import { ReactElement } from 'react'
import {
  GetStaticPropsContext,
  InferGetStaticPropsType,
  NextPageContext
} from 'next'

import Meta from '@/components/base/Meta'
import Layout from '@/components/layout/Layout'
import { mapPageStaticProps } from '@/components/layout/utils'
import PageBuilder from '@/components/pages/PageBuilder'
import { getPromiseFromFetch } from '@/utils/request'

import type { WPPageData } from '@/types'

export default function Home(
  pageData: InferGetStaticPropsType<typeof getStaticProps>
) {
  return <PageBuilder pageData={pageData} />
}

Home.getLayout = function getLayout(
  page: ReactElement,
  props: NextPageContext & WPPageData
) {
  return (
    <Layout translations={props.wpml_translations}>
      <Meta pageData={props} />
      {page}
    </Layout>
  )
}

export async function getStaticProps({ locale }: GetStaticPropsContext) {
  if (locale === 'default') return { notFound: true }

  const { data: pageData } = await getPromiseFromFetch<WPPageData>({
    method: 'get',
    url: `/${locale}`
  })

  return {
    props: {
      ...mapPageStaticProps(pageData)
    }
  }
}
